import {defineStore} from 'pinia';
import {useModal} from "./ModalStore.js";
import Alert from "../components/Alert.vue";

export const useAlertStore = defineStore('alert', () => {

    function success(title, message) {
        useModal().openModal('AlertModal', Alert, {
            type: 'success',
            title,
            message
        });
    }

    function saved(message) {
        success('Saved!', message);
    }

    return {
        success,
        saved
    };
});
